<template>
  <b-modal
    id="exam-package-request-modal"
    hide-header
    hide-footer
    centered size="xl"
    @hide="onHide"
    @show="onShow"
  >
    <div class="modal-header">
      <div class="modal-title">Pacote de exames</div>
      <span class="icon-box"><Close class="icon stroke" @click="closeModal" /></span>
    </div>
    <div class="md-content">
      <div class="search-wrapper">
        <b-form-group class="exames-search">
          <Search class="icon ml-2" />
          <b-form-input
            v-model="search"
            placeholder="Buscar exames"
            debounce="500"
          ></b-form-input>
        </b-form-group>
        <b-button
          class="new-button"
          variant="outline-primary"
          @click="createExamPackage"
          >
            Novo Pacote de Exames
          </b-button
        >
      </div>

      <div class="badge-container">
        <b-badge
          v-for="(examPackage, index) in selectedPackages"
          :key="index"
          pill
          variant="secondary"
        >
          <div class="badge-content">
            <span>{{ examPackage.name }}</span>
            <Close class="badge-close" @click="removePackage(index)" />
          </div>
        </b-badge>
      </div>

      <table class="table-eyecare">
        <thead>
          <tr>
            <th width="45%">Pacote de Exame</th>
            <th>Observação</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(examPackage, index) in examPackages" :key="index">
            <td class="py-3">
              <div class="check-wrapper">
                <Check :value="selectedPackages.some(el => el.id === examPackage.id)" @change="checked => handleSelectedPackage(examPackage, checked)" />
                <span>{{ examPackage.name }}</span>
              </div>
            </td>
            <td>
              <div class="exam-package-comment">
                <Ellipsis>{{ examPackage.comment }}</Ellipsis>
              </div>
            </td>
            <td class="options-td">
              <div class="actions">
                <Icon tooltip="Editar Pacote de Exames">
                  <Edit class="icon" @click="editExamPackage(examPackage)" />
                </Icon>
                <Icon tooltip="Remover Pacote de Exames" class="ml-2">
                  <Delete class="icon" @click="deleteModal(examPackage)" />
                </Icon>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="pagination-position">
        <b-pagination
          v-model="page"
          :total-rows="count"
          :per-page="5"
          first-number
          last-number
          size="sm"
        />
      </div>

      <b-row class="mt-3" align-v="end">
        <b-col class="text-right">
          <b-button
            size="lg"
            variant="primary"
            :disabled="selectedPackages.length === 0 || !canEdit"
            @click="addPackageExamsToAttendance"
          >
            Usar Pacote{{ selectedPackages.length > 1 ? 's' : '' }} na Consulta
          </b-button>
        </b-col>
      </b-row>
    
      <ExamPackageModalForm :examPackage="examPackage" :editMode="editMode" />
      
    </div>

  </b-modal>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import { getCurrentClinic } from '@/utils/localStorageManager'
export default {
  components: {
    Close: () => import('@/assets/icons/close.svg'),
    Check: () => import('@/components/General/Check'),
    Delete: () => import('@/assets/icons/delete.svg'),
    Edit: () => import('@/assets/icons/edit.svg'),
    Icon: () => import('@/components/General/Icon'),
    Search: () => import('@/assets/icons/search.svg'),
    Ellipsis: () => import('@/components/General/Ellipsis'),
    ExamPackageModalForm: () => import('@/components/Attendance/Forms/Modules/ExamRequest/ExamPackageModalForm'),
  },
  computed: {
    ...mapState({
      canEdit: state => state.attendance.canEdit,
      form: state => state.attendance.form.solicitacaoDeExames,
      /**
       * @param {Array} state
       * @returns {Array}
       */
      eyeExamsList: state => state.attendance.eyeExams.list,
      examPackages: state => state.attendance.examPackageRequest.examPackages,
      count: state => state.attendance.examPackageRequest.count,
    })
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      options: [],
      selectedPackages: [],
      examPackage: this.getDefaultFields(),
      editMode: false,
      search: '',
      page: 1,
      filters: {
        exames: null
      },
    }
  },
  async mounted() {
    await this.getExamPackages({ page: this.page, clinicId: this.clinic.id, search: this.search })
  },
  methods: {
    ...mapActions('attendance/form', [
      'updateMedicalRecord',
      'destroyMedicalRecord'
    ]),
    ...mapActions('attendance/form/solicitacaoDeExames', [
      'handleFields',
      'handleProps'
    ]),
    ...mapActions('attendance/examPackageRequest', [
      'getExamPackages',
      'deleteExamPackage'
    ]),
    closeModal() {
      this.$bvModal.hide('exam-package-request-modal')
    },
    onShow() {
      this.selectedPackages = []
    },
    onHide() {
      this.search = ''
    },
    handleSelectedPackage(examPackage, checked) {
      if (checked) {
        this.selectedPackages.push(examPackage)
        return
      }
      this.selectedPackages = this.selectedPackages.filter(
        pkg => pkg.id !== examPackage.id
      )
    },
    removePackage(index) {
      this.selectedPackages.splice(index, 1)
    },
    getDefaultFields() {
      return {
        clinic_id: null,
        author_id: null,
        exams: [],
        comment: null,
        name: null
      }
    },
    getExamRequestDefaultFields() {
      return {
        custom: false,
        exame: null,
        comentario: null,
        template: null,
        examLaterality: null,
        examLateralityModel: null,
        updatedTemplate: false,
        origin: 'package'
      }
    },

    async deleteModal(examPkg) {
      this.$swal({
        icon: 'question',
        html: `<div class="body-alert">
          Você tem certeza que deseja excluir o Pacote de Exames
          <span>${examPkg.name}</span>?
          <p>Não será possível reverter essa ação.</p>
          </div>`,
        showCancelButton: true,
        confirmButtonColor: '#F63220',
        confirmButtonText: 'Excluir',
        cancelButtonText: 'Cancelar Exclusão',
        reverseButtons: true
      }).then(async res => {
        if (res.isConfirmed) {
          await this.deleteExamPackage(examPkg.id).then(res => {
            this.$toast.success(
              `Pacote de Exames ${examPkg.name} removido com sucesso`
            )
          })
        }
      })
    },

    openModal() {
      this.$bvModal.show('exam_package_modal')
    },
    editExamPackage(examPackage) {
      this.examPackage = examPackage
      this.editMode = true
      this.openModal()
    },
    createExamPackage() {
      this.editMode = false
      this.examPackage = this.getDefaultFields()
      this.openModal()
    },
    /**
     * Add all the exams contained in the package to the attendance
     * exam request list
     */
    addPackageExamsToAttendance() {
    
      let packages = [...this.form.value]
      // let hasExam = null

      this.selectedPackages.map(pkg => {
        if (packages.some(p => p.name === pkg.name)) return
        packages.push({
          name: pkg.name,
          observation: pkg.comment,
          exams: pkg.exams.map(exam => {
            if (exam instanceof Object) {
              return {
                custom: exam.custom ?? false,
                exame: exam.value ?? null,
                comentario: exam.comment ?? null,
                template: exam.template ?? null,
                examLaterality: exam.examLaterality ?? null,
                examLateralityModel: exam.examLateralityModel ?? null,
                updatedTemplate: exam.updatedTemplate ?? false,
                origin: 'package'
              }
            } else {
              const foundExam = this.eyeExamsList.find(exm => exm.id === exam)
              if (foundExam) {
                const fields = this.getExamRequestDefaultFields()
                fields.exame = foundExam.value
                return fields
              }
            }
          })
        })
      })



      if (!this.hasDuplicatedExam(packages)) {
        this.addPackagesToAttendace(packages)
        return
      }

      this.$swal({
        icon: 'question',
        html: `<div class="body-alert">Existem exames repetidos entre os pacotes selecionados, deseja remover as duplicidades? Caso um pacote fique vazio o mesmo será removido.</div>`,
        showCancelButton: true,
        confirmButtonColor: '#305bf2',
        confirmButtonText: 'Remover',
        cancelButtonText: 'Não remover',
        reverseButtons: true
      }).then(res => {
        if (res.isConfirmed) {
          packages = this.removeDuplicatedExams(packages)
          this.addPackagesToAttendace(packages)
        } else {
          this.addPackagesToAttendace(packages)
        }
      })
      
    },
    addPackagesToAttendace(packages) {
      packages = packages.filter(pkg => pkg.exams.length > 0)
      this.handleProps({ key: 'value', value: packages })
      this.updateMedicalRecord(this.form).then(res => {
        this.$toast.success(
          `Exames adicionados com sucesso à solicitação de exames`
        )
      })
      this.closeModal()
    },
    hasDuplicatedExam(packages) {
      const exams = []
      let hasDuplicated = false
      packages.forEach(pkg => {
        pkg.exams.forEach(exam => {
          if (exams.includes(exam.exame)) {
            hasDuplicated = true
          }
          exams.push(exam.exame)
        })
      })
      return hasDuplicated
    },
    removeDuplicatedExams(packages) {
      const exams = []
      packages.forEach((pkg, packageIndex) => {
        pkg.exams.forEach((exam, examIndex) => {
          if (exams.includes(exam.exame)) {
            packages[packageIndex].exams.splice(examIndex, 1)
            return
          }
          exams.push(exam.exame)
        })
      })
      return packages
    },
  },
  watch: {
    page(value) {
      this.getExamPackages({ page: value, clinicId: this.clinic.id, search: this.search })
    },
    search(value) {
      this.getExamPackages({ page: 1, clinicId: this.clinic.id, search: value })
    }
  }
}
</script>
<style lang="scss">
#exam-package-request-modal {
  .modal-header {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
  }
  .modal-title {
    font-size: 18px;
    color: var(--dark-blue);
    font-weight: 600;
  }
  .icon {
    width: 25px;
  }
  .md-content {
    padding: 30px 20px 10px 20px;
  }
  .check-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .search-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }
  .badge-container {
    display: flex;
    flex-direction: row;
    gap: 5px;
    padding: 1rem 0;
  }
  .badge-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }
  .badge-close {
    width: 10px;
    height: 10px;
    fill: white;
    cursor: pointer;
  }
}
</style>
<style lang="scss" scoped>
.exam-request {
  display: flex !important;
  flex-direction: column !important;

  .new-template {
    font-weight: 700;
    color: var(--blue-500);
  }

  .multiselect {
    border: 1px solid #c6ceeb;
    border-radius: 8px;
  }

  .icon {
    fill: var(--blue-500);
    width: 26px;
    margin-right: 8px;
  }
}

.table-eyecare {
  padding: 30px;
}

.multiselect {
  .custom-item {
    color: var(--blue-500);
    display: flex;
    align-items: center;
    font-weight: 600;

    .icon {
      width: 24px;
      height: 24px;
      fill: var(--blue-500);
      margin-right: 12px;
    }
  }

  .multiselect__single {
    @media (min-height: 1600) {
      font-size: 1.6vh;
    }

    @media (max-height: 1600) {
      font-size: 1vw;
    }
  }
}

.button {
  height: 38px;
  margin-bottom: 24px;
}

.delete {
  background-color: transparent;
  border: none;
}
.delete:disabled {
  cursor: not-allowed;
}
.actions {
  justify-content: right;
  display: flex;
  .icon {
    width: 24px;
    height: 24px;
    margin-right: 6px;
    cursor: pointer;
  }
  .delete:disabled {
    .icon {
      cursor: not-allowed;
    }
  }
}
.exam-package-comment {
  font-size: 14px;
}

.exames-search {
    width: 100%;
    margin-bottom: 0;
    .icon {
      stroke: var(--neutral-500);
      height: 20px;
      width: 20px;
      margin-top: 8px;
      position: absolute;
    }

    input {
      padding-left: 35px;
    }
  }

  .new-button {
    white-space: nowrap;
  }
</style>
